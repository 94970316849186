<template>
  <div class="test">
    <header class="header">
      <div class="now">
        <div class="date">{{ +new Date() | dateNow }}</div>
        <div class="time">{{ nowTime }}</div>

        <div class="weather">
          <i class="img" :class="'qi-' + weatherObj.icon + '-fill'"></i>

          <span>{{ weatherObj.text }} {{ weatherObj.temp }}℃</span>
        </div>
      </div>
      <div class="logo">
        <img class="logo-img" :src="$imgOnlineUrl + '/bigWeb/head/DK-1.png'" />
        <span class="logo-txt">大旭智能智慧农业平台</span>
      </div>

      <div class="title">
        <img
          class="other-txt"
          :src="$imgOnlineUrl + '/bigWeb/head/icon.png'"
          alt="后台界面"
          title="后台界面"
          @click="goReturn"
        />
        大旭数字农业平台
      </div>
    </header>
    <div class="main animated fadeInUp">
      <div class="main-left">
        <!-- 设备联网状态 -->
        <div class="equipment">
          <p class="title">设备联网状态</p>
          <div class="lists">
            <div class="items" v-for="(item, index) in 4" :key="index">
              <div class="items-title">阀门开关</div>
              <div class="item-switch">
                <!-- v-for="(item, index) in 2"
                  :key="index"
                  @click="changeSwitch(index)" -->
                <div class="switches">
                  <p class="name one-txt-cut" title="向上">向上</p>
                  <img
                    class="switch-img switch-close"
                    :src="$imgOnlineUrl + '/bigWeb/equipment/close.png'"
                    title="关闭"
                    v-show="!openNum"
                  />
                  <img
                    class="switch-img switch-open"
                    :src="$imgOnlineUrl + '/bigWeb/equipment/open.png'"
                    title="开启"
                    v-show="openNum"
                  />
                  <span :class="['status', openNum ? 'active' : '']">{{
                    openNum ? "开" : "关"
                  }}</span>
                </div>
                <div class="switches">
                  <p class="name one-txt-cut" title="向下">向下</p>
                  <img
                    class="switch-img switch-close"
                    :src="$imgOnlineUrl + '/bigWeb/equipment/close.png'"
                    title="关闭"
                    v-show="!openNum"
                  />
                  <img
                    class="switch-img switch-open"
                    :src="$imgOnlineUrl + '/bigWeb/equipment/open.png'"
                    title="开启"
                    v-show="openNum"
                  />
                  <span :class="['status', openNum ? 'active' : '']">{{
                    openNum ? "开" : "关"
                  }}</span>
                </div>
                <div class="switches">
                  <p class="name one-txt-cut" title="关停">关停</p>
                  <img
                    class="switch-img switch-close"
                    :src="$imgOnlineUrl + '/bigWeb/equipment/close.png'"
                    title="关闭"
                    v-show="!openNum"
                  />
                  <img
                    class="switch-img switch-open"
                    :src="$imgOnlineUrl + '/bigWeb/equipment/open.png'"
                    title="开启"
                    v-show="openNum"
                  />
                  <span :class="['status', openNum ? 'active' : '']">{{
                    openNum ? "开" : "关"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 人员数据 -->
        <div class="personnel">
          <p class="title">人员数据</p>
          <div class="all-data">
            <span>农场总人数：88人</span>
            <span>管理人员：12人</span>
            <span>劳动人员：76人</span>
          </div>
          <div class="chart-personnel">
            <v-chart
              class="v-chart"
              autoresize
              :option="line_personnel"
            ></v-chart>
          </div>
        </div>
        <!-- 溯源动态 -->
        <div class="traceability">
          <p class="title">溯源动态</p>
          <div class="traceability-lists">
            <vue-seamless-scroll
              :data="traceabilityList"
              :class-option="defaultOption"
            >
              <div
                class="traceability-items"
                v-for="item in traceabilityList"
                :key="item.id"
              >
                <span class="name one-txt-cut" :title="item.title">{{
                  item.title
                }}</span>
                <span class="city one-txt-cut" :title="item.plot_title">{{
                  item.plot_title
                }}</span>
                <span class="time one-txt-cut" :title="item.created_time">{{
                  item.created_time
                }}</span>
                <span>{{ item.product_title }}</span>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="main-center">
        <!-- 其他信息 -->
        <div class="other">
          <!-- 农事计划 -->
          <div class="chart-items">
            <v-chart class="v-chart" autoresize :option="option" />
          </div>
          <!-- 环境监测历史数据 -->
          <div class="chart-atmosphere">
            <v-chart class="v-chart" autoresize :option="atmosphereOption" />
          </div>
          <!-- 气象预警 -->
          <div class="warning">
            <img :src="$imgOnlineUrl + '/bigWeb/other/warning.png'" />
            <marquee
              class="warning-text"
              align="left"
              behavior="scroll"
              direction="left"
              scrollamount="10"
              scrolldelay="100"
              onMouseOut="this.start()"
              onMouseOver="this.stop()"
            >
              <!-- 气象预警：预计今日有小雨，同时伴有3级大风，请注意防范！ -->
              暂无
            </marquee>
          </div>
          <!-- 设备统计 -->
          <div class="statistics">
            <div class="statistics-items">
              <img
                class="imgs"
                :src="$imgOnlineUrl + '/bigWeb/other/icon1.png'"
              />
              <div class="right-info">
                <span class="top-span">{{ stat.all_count }}</span>
                <span class="bottom-span">设备总数</span>
              </div>
            </div>
            <div class="statistics-items">
              <img
                class="imgs"
                :src="$imgOnlineUrl + '/bigWeb/other/icon3.png'"
              />
              <div class="right-info">
                <span class="top-span">{{ stat.control_count }}</span>
                <span class="bottom-span">控制总数</span>
              </div>
            </div>
            <div class="statistics-items">
              <img
                class="imgs"
                :src="$imgOnlineUrl + '/bigWeb/other/icon2.png'"
              />
              <div class="right-info">
                <span class="top-span">{{ stat.collect_count }}</span>
                <span class="bottom-span">采集总数</span>
              </div>
            </div>
            <div class="statistics-items">
              <img
                class="imgs"
                :src="$imgOnlineUrl + '/bigWeb/other/icon4.png'"
              />
              <div class="right-info">
                <span class="top-span">{{ stat.v_count }}</span>
                <span class="bottom-span">摄像头总数</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 实时监测数据 -->
        <div class="real-time-data">
          <p class="title now-data">实时监测数据</p>
          <div class="data-content">
            <div class="content-items weather-cont">
              <p class="contents-title">实时环境监测</p>
              <div
                class="contents-main-items weather-cont-items"
                v-for="item in chemicalList.qixiang"
                :key="item.id"
              >
                <div class="l">
                  <img
                    v-if="item.online == 1"
                    :src="
                      $imgOnlineUrl + '/bigWeb/realData/' + item.icon + '.png'
                    "
                  />
                  <img
                    v-else
                    :src="
                      $imgOnlineUrl +
                      '/bigWeb/realData/' +
                      item.icon +
                      '-offline.png'
                    "
                  />
                  <span>{{ item.title }}</span>
                </div>
                <div v-if="item.online == 1" class="r">
                  {{ item.last_data }}{{ item.data_unit }}
                </div>
              </div>
            </div>
            <div class="content-items weather-cont">
              <p class="contents-title">实时土壤监测</p>
              <div
                class="contents-main-items weather-cont-items"
                v-for="item in chemicalList.turang"
                :key="item.id"
              >
                <div class="l">
                  <img
                    v-if="item.online == 1"
                    :src="
                      $imgOnlineUrl + '/bigWeb/realData/' + item.icon + '.png'
                    "
                  />
                  <img
                    v-else
                    :src="
                      $imgOnlineUrl +
                      '/bigWeb/realData/' +
                      item.icon +
                      '-offline.png'
                    "
                  />
                  <span>{{ item.title }}</span>
                </div>
                <div v-if="item.online == 1" class="r">
                  {{ item.last_data }}{{ item.data_unit }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="monitor">
          <p class="title">视频监控</p>
          <div class="monitor-lists">
            <div
              class="monitor-items"
              v-for="item in chemicalList.jiankong"
              :key="item.id"
            >
              <iframe
                frameborder="0"
                name="video"
                scrolling="no"
                width="192"
                height="136"
                :src="`https://data.darsing.net/project/digital2/video/address?eid=${item.id}&return_type=iframe`"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="quotation">
          <p class="title">产品行情</p>
          <div class="chart-div">
            <v-chart
              class="v-chart"
              autoresize
              :option="option_column"
            ></v-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
use([
  BarChart,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);

import moment from "moment";
import {
  getEquipmentGroupApi,
  getEquipmentListApi,
  getEquipmentInfoApi,
  getEquipmentChildListApi,
  getEquipmentCountApi,
  getWeatherApi,
  getTaskCountApi,
  getBatchListApi,
  getProductListApi,
  getEquipmentChildListInfoApi,
} from "@/request/api";
import * as R from "ramda";
import { mapState } from "vuex";
export default {
  components: { VChart },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      // 天气
      weatherObj: {},
      //设备总数
      stat: {},
      // 视频分页
      videoPageNum: 1,
      videoPageSize: 10,
      videoCount: 0,
      videoId: "",
      // 设备
      chemicalList: {
        turang: [],
        qixiang: [],
        jiankong: [],
      },
      // 定时器
      setInterval: null,
      setInterval2: null,

      openNum: true,
      // 溯源动态
      traceabilityList: [],
      // 实时时间
      nowTime: "",
      // 任务 饼状图
      option: {
        title: {
          text: "农事计划",
          left: "left",
          textStyle: {
            fontSize: 18,
          },
          padding: 20,
        },
        tooltip: {
          show: true,
          trigger: "item",
        },
        color: ["#5470c6", "#91cc75", "#ee6666"],
        backgroundColor: "transparent",
        legend: {
          orient: "vertical",
          left: "center",
          bottom: "bottom",
          padding: 20,
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: [
              { value: "", name: "已完成任务" },
              { value: "", name: "进行中任务" },
              { value: "", name: "待完成任务" },
            ],
            label: {
              show: true,
              formatter: "{d}%",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      // 农产品 柱状图
      option_column: {
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          name: "批次",
          nameGap: 30,
          nameLocation: "end",
          nameTextStyle: {
            color: "#fff",
            fontSize: 14,
            align: "right",
          },
          type: "value",
          // min: 0,
          // max: 1,
          // splitNumber: 5,
        },
        series: [
          {
            type: "bar",
            data: [],
            barMaxWidth: 20,
          },
        ],
      },
      // 人员 折线图
      line_personnel: {
        xAxis: {
          type: "category",
          data: ["0", "4", "8", "12", "16", "20", "24"],
          boundaryGap: false,
          interval: 55,
        },
        yAxis: {
          name: "单位",
          nameLocation: "end",
          nameGap: 14,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            align: "right",
          },

          type: "value",
          min: 0,
          max: 100,
          splitNumber: 6,
          interval: 21,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#36B77F",
          borderColor: "#36B77F",
          padding: [7, 12],
          formatter: "{b}号<br/> 出勤人数：{c}",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "line",
            data: [1, 54, 77, 24, 15, 87, 38],
            smooth: true,
            showSymbol: false,
            lineStyle: {
              color: "rgb(54,183,127)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#36B77F",
              color: "#fff",
            },
            areaStyle: {
              color: "rgba(54,183,127,.28)",
            },
          },
        ],
      },
      // 环境 折线图
      atmosphereOption: {
        title: {
          text: "环境监测历史数据",
          left: "left",
          textStyle: {
            fontSize: 18,
            height: 40,
          },
          // padding: 20,
        },
        backgroundColor: "transparent",

        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
        },
        yAxis: {
          name: "气温（℃）",
          nameLocation: "end",
          nameGap: 14,
          boundaryGap: false,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            align: "center",
          },

          type: "value",
          min: 0,
          max: 45,
          splitNumber: 7,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#36B77F",
          borderColor: "#36B77F",
          padding: [7, 12],
          formatter: "时间：{b}<br/> 温度：{c} ℃",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "line",
            data: [],
            smooth: false,
            showSymbol: false,
            lineStyle: {
              color: "rgb(13,183,204)",
              width: 2,
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#fff",
              color: "#02D9FD",
            },
            areaStyle: {
              color: "rgba(13,183,204,.25)",
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmInfo: (state) => state.user.farmInfo,
    }),
    defaultOption() {
      return {
        step: 0.2,
        limitMoveNum: 2,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
  created() {
    this.getNowTime();
    this.allFunction();
    this.updatePage();
  },
  beforeDestroy() {
    // 卸载定时器
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
    this.setInterval = null;
    if (this.setInterval2) {
      clearInterval(this.setInterval2);
    }
    this.setInterval2 = null;
  },

  methods: {
    allFunction() {
      this.totalEquipmentCount();
      this.loadFullDataSets();
      this.getWeatherApi();
      this.getTaskCountApi();
      this.getBatchListApi();
      this.getProductListApi();
      // this.getEquipmentChildListInfoApi();
    },
    // //10分钟更新一次
    updatePage() {
      this.setInterval2 = setInterval(() => {
        this.allFunction();
      }, 600000);
    },
    // async getEquipmentGroupApi() {
    //   const { code, data } = await getEquipmentGroupApi();
    //   if (code !== 200) return;
    //   for (const key in data) {
    //     // 虫情设备
    //     if (data[key].icon == "insect") {
    //       // this.insectTableId = data[key].id;
    //       // this.getQuantityEquipmentListApi(data[key].id);
    //     }
    //   }
    // },
    // 环境历史数据
    async getEquipmentChildListInfoApi(table_id, child_id) {
      let data = {
        manor_id: this.farmID,
        table_id,
        child_id,
        act: "history",
      };
      const { code, results } = await getEquipmentChildListInfoApi(data);
      if (code === 0) {
        this.atmosphereOption["xAxis"]["data"] = [];
        this.atmosphereOption["series"][0]["data"] = [];
        results.data.forEach((a) => {
          let xAxis = a["x"].substring(11, 16);
          this.atmosphereOption["xAxis"]["data"].push(xAxis);
          this.atmosphereOption["series"][0]["data"].push(a["y"]);
        });
      }
    },
    async getProductListApi() {
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.option_column["xAxis"]["data"] = [];
        this.option_column["series"][0]["data"] = [];
        results.data.forEach((a) => {
          this.option_column["xAxis"]["data"].push(a["title"]);
          this.option_column["series"][0]["data"].push(a["batch_count"]);
        });
      }
    },
    goReturn() {
      this.$router.go(-1);
    },
    async getBatchListApi() {
      this.product_id = this.$route.query.id;
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
        pn: 1,
      });
      if (code === 0) {
        this.traceabilityList = results.data;
      }
    },
    async getTaskCountApi() {
      const { code, results } = await getTaskCountApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.option.series[0].data[0].value = results.data.find((item) => {
          return item.state == 2;
        }).count;
        this.option.series[0].data[1].value = results.data.find((item) => {
          return item.state == 1;
        }).count;
        this.option.series[0].data[2].value = results.data.find((item) => {
          return item.state == 0;
        }).count;
        if (
          this.option.series[0].data[0].value == 0 &&
          this.option.series[0].data[1].value == 0 &&
          this.option.series[0].data[2].value == 0
        ) {
          this.option.series[0].data[2].value = 1;
        }
        this.$forceUpdate();
      }
    },
    async getWeatherApi() {
      const { code, data } = await getWeatherApi({
        location: this.farmInfo.position,
      });
      if (code === 200) {
        this.weatherObj = data.now;
      }
    },
    // 获取时间
    getDateList(count, long) {
      let time = new Date().setMinutes(0);
      time = time = 24 * 60 * 60 * 1000;
      let categoryData = [];
      for (let i = 0; i <= count; i++) {
        categoryData.push(moment(time).format("HH:mm"));
        time += long;
      }
      return categoryData;
    },
    // 设备开关
    changeSwitch() {
      this.openNum = !this.openNum;
    },
    // 获取实时时间
    getNowTime() {
      let that = this;
      let theNowTime = function () {
        that.nowTime = that.timeNumber();
      };
      this.setInterval = setInterval(theNowTime, 1000);
    },
    timeNumber() {
      let today = new Date();
      let time =
        this.twoDigits(today.getHours()) +
        ":" +
        this.twoDigits(today.getMinutes());
      return time;
    },
    twoDigits(val) {
      if (val < 10) return "0" + val;
      return val;
    },

    async totalEquipmentCount() {
      const { code, data } = await getEquipmentCountApi({
        manor_id: this.farmID,
      });
      if (code === 200) {
        this.stat = data;
      }
    },

    async loadFullDataSets() {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200 && data) {
        for (const index in data) {
          this.loadSepecificDataSet(data[index]);
        }
      }
    },

    async loadSepecificDataSet(data) {
      switch (data["name"]) {
        case "土壤检测":
          this.getChemicalList("turang", data["id"]);
          break;
        case "气象采集":
          this.getChemicalList("qixiang", data["id"]);
          break;
        case "视频监控":
          this.videoId = data["id"];
          this.getVideoList();
          break;
        default:
          break;
      }
    },

    async getVideoList(pageNum = 1) {
      const { code, results, count, page_num, page_size } =
        await getEquipmentListApi({
          manor_id: this.farmID,
          channel_id: this.videoId,
          page_num: pageNum,
        });
      if (code === 0 && Array.isArray(results)) {
        this.chemicalList["jiankong"] = results.slice(0, 6);

        this.videoCount = count;
        this.videoPageNum = page_num;
        this.videoPageSize = page_size;
        this.$forceUpdate();
      }
    },
    async getChemicalList(type, channel_id) {
      const { code, results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id,
      });

      if (code === 0 && Array.isArray(results)) {
        let chemicalBasics = results[0];
        const mergeObj = (a, b) => {
          return { ...a, ...b, last_data: b.last_data };
        };

        if (chemicalBasics) {
          this.chemicalList[type] = R.zipWith(
            mergeObj,
            await this.getChemicalChildList(chemicalBasics.id),
            await this.getChemicalInfo(chemicalBasics.id)
          );
        }
        if (type === "jiankong") {
          this.chemicalList["jiankong"] = results;
        }
        this.chemicalList[type].pop();
      }
    },

    async getChemicalInfo(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (code === 0 && results) {
        return results.data;
      }
    },

    async getChemicalChildList(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
      });
      if (code === 0 && results) {
        results.forEach((t) => {
          if (t.alias == "airT") {
            this.getEquipmentChildListInfoApi(table_id, t.id);
          }
        });

        return results;
        // 眼
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  // min-width: 1920px;
  min-height: 100vh;
  background: #051218;
  .header {
    width: 100%;
    height: 72px;
    padding: 0 35px;

    background: url(../../assets/image/bigWeb/head/top.png) center center
      no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .now {
      display: flex;
      align-items: center;
      .date {
        font-size: 21px;
        font-weight: 500;
        color: #acd1ff;
        margin-right: 47px;
      }
      .time {
        width: 100px;
        font-size: 36px;
        font-family: DS-Digital;
        font-weight: bold;
        color: #acd1ff;
        margin-right: 38px;
      }
      .weather {
        height: 23px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        border-left: 1px solid #3e90e5;
        .img {
          margin-right: 15px;
          color: #3e90e5;
          font-size: 36px;
        }
        span {
          font-size: 21px;
          font-weight: 500;
          color: #acd1ff;
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .logo-img {
        width: 56px;
        height: 26px;
        margin-right: 20px;
      }
      .logo-txt {
        font-size: 32px;
        font-weight: bold;
        color: #fff;
      }
    }

    .title {
      font-size: 21px;
      font-weight: 500;
      color: #acd1ff;
      display: flex;
      align-items: center;
      .other-txt {
        width: 27px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .main {
    // padding: 0 21px;
    margin: 30px 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .equipment,
    .traceability,
    .monitor,
    .quotation,
    .personnel,
    .real-time-data {
      width: 425px;
      width: 424px;
      height: 303px;
      background: url("../../assets/image/bigWeb/equipment/backdrop.png") center
        center no-repeat;
      background-size: cover;
      position: relative;
      padding: 54px 8px 13px;
      margin-bottom: 25px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: transparent;
        background: linear-gradient(0deg, #03b8de 0%, #0085ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: absolute;
        top: 9px;
        left: 51px;
        &.now-data {
          top: 12px;
          left: 61px;
        }
      }
      .lists {
        width: 100%;

        .items {
          width: 100%;
          height: 51px;
          background: #0e3248;
          padding: 0 8px;
          margin: 0 0 11px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:last-of-type {
            margin: 0;
          }
          .items-title {
            font-size: 14px;
            color: #fff;
            position: relative;
            padding-left: 13px;
            &::before {
              content: "";
              width: 2px;
              height: 12px;
              background: #3e90e5;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .item-switch {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .switches {
              display: flex;
              align-items: center;

              .name {
                width: 60px;
                font-size: 12px;
                color: #fff;
                display: inline-block;
                text-align: right;
              }
              .switch-img {
                width: 18px;
                height: 18px;
                vertical-align: sub;
                margin: 0 8px 0 18px;
                cursor: pointer;
              }

              .status {
                font-size: 12px;
                color: #e6855b;
                &.active {
                  color: #3e90e5;
                }
              }
            }
          }
        }
      }
    }
    .traceability {
      .traceability-lists {
        height: 100%;
        width: 100%;
        overflow: hidden;
        .traceability-items {
          width: 100%;
          height: 39px;
          background: rgba(14, 50, 72, 0.3);
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;
          cursor: pointer;
          &:nth-of-type(2n) {
            background: #051218;
          }
          &:last-of-type {
            margin: 0;
          }
          span {
            color: #fff;
            font-size: 12px;
            &.name {
              width: 36px;
            }
            &.city {
              width: 72px;
            }
            &.time {
              width: 114px;
            }
          }
        }
      }
    }
    .monitor {
      width: 426px;
      height: 548px;
      background: url("../../assets/image/bigWeb/monitor/backdrop.png") center
        center no-repeat;
      .monitor-lists {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        // overflow-y: auto;
        // &::-webkit-scrollbar {
        //   width: 7px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   background-color: #3e90e5;
        // }
        .monitor-items {
          width: 192px;
          height: 136px;
          margin: 0 12px 26px 0;
          overflow: hidden;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .other {
      width: 984px;
      height: 631px;
      background: url("../../assets/image/bigWeb/other/backdrop.png") center
        center no-repeat;
      background-size: cover;
      position: relative;
      margin-bottom: 25px;
      .warning {
        width: 610px;
        height: 39px;
        border-radius: 3px;
        background-color: rgba(154, 0, 0, 0.5);
        border: 1px solid rgba(162, 0, 0, 1);
        display: flex;
        align-items: center;
        padding: 0 17px;
        font-size: 16px;
        color: #fff;
        position: absolute;
        top: 27px;
        left: 27px;
        img {
          width: 22px;
          height: 20px;
          margin-right: 24px;
        }
        .warning-text {
          width: calc(100% - 46px);
        }
      }
      .statistics {
        width: 297px;
        height: 177px;
        background: url("../../assets/image/bigWeb/other/statistics.png") center
          center no-repeat;
        background-size: cover;
        position: absolute;
        right: 30px;
        bottom: 37px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .statistics-items {
          width: 50%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .imgs {
            width: 36px;
            height: 36px;
            margin-right: 14px;
          }
          .right-info {
            width: 45%;
            display: flex;
            flex-direction: column;

            .top-span {
              font-size: 24px;
              font-family: DS-Digital;
              font-weight: bold;
              color: #f5c500;
            }
            .bottom-span {
              width: 100%;
              display: inline-block;
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
      .chart-items {
        width: 297px;
        height: 356px;
        background: url("../../assets/image/bigWeb/other/chart1.png") center
          center no-repeat;
        background-size: cover;
        position: absolute;
        top: 23px;
        right: 30px;
        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
      // 环境
      .chart-atmosphere {
        width: 436px;
        height: 243px;
        background: url("../../assets/image/bigWeb/other/chart2.png") center
          center no-repeat;
        background-size: cover;
        padding: 10px 0 10px;
        position: absolute;
        bottom: 18px;
        left: 33px;
        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
    .real-time-data {
      width: 985px;
      background: url("../../assets/image/bigWeb/realData/backdrop.png") center
        center no-repeat;
      padding: 60px 19px 13px;
      .data-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .content-items {
          flex: 2;
          .contents-title {
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            position: relative;
            padding-left: 13px;
            margin: 0 28px 13px 0;
            &::before {
              content: "";
              width: 2px;
              height: 12px;
              background: #3e90e5;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .contents-main {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .contents-main-items {
              width: 226px;
              height: 45px;
              background: #0e3248;
              margin: 0 9px 7px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 13px;
              &:nth-of-type(2n) {
                margin: 0;
              }
              div.l {
                display: flex;
                align-items: center;
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 10px;
                }
                font-size: 12px;
              }
              div.r {
                font-size: 16px;
                font-weight: 500;
                &.active {
                  color: #f2423e;
                }
              }
            }
          }
          &.weather-cont {
            display: flex;
            flex-wrap: wrap;
            .contents-title {
              width: 226px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #fff;
              font-weight: bold;
              position: relative;
              margin: 0 9px 7px 0;
              padding: 0 13px;

              &::before {
                content: "";
                width: 2px;
                height: 12px;
                background: #3e90e5;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .contents-main-items.weather-cont-items {
              width: 226px;
              height: 40px;
              background: #0e3248;
              margin: 0 9px 7px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 13px;
              // &:nth-of-type(2n) {
              //   margin: 0;
              // }
              div.l {
                display: flex;
                align-items: center;
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 10px;
                }
                font-size: 12px;
              }
              div.r {
                font-size: 16px;
                font-weight: 500;
                &.active {
                  color: #f2423e;
                }
              }
            }
          }
        }
      }
    }
    .quotation {
      width: 424px;
      height: 385px;
      background: url("../../assets/image/bigWeb/quotation/backdrop.png") center
        center no-repeat;
      .chart-div {
        width: 100%;
        height: 100%;

        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
    .personnel {
      width: 426px;
      height: 305px;
      .all-data {
        width: 100%;
        display: flex;
        justify-content: space-around;
        color: #fff;
        font-size: 12px;
      }
      .chart-personnel {
        width: 100%;
        height: 113%;

        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
